import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

export default class Menu extends React.Component {
    render() {
        let catInv = [{id:'especialidades',nombre:'Especialidades'},{id:'pacientes',nombre:'Pacientes'},{id:'-',nombre:'-'},{id:'usuarios',nombre:'Usuarios'}];
        let movInv = [{id:'citas',nombre:'Citas'},{id:'consultas',nombre:'Consultas'}];
        let conInv = [{id:'kardexUsu',nombre:'Actividades por usuario'},{id:'kardexCte',nombre:'Actividades por cliente'}];
        let repInv = [{id:'rptKardexUsu',nombre:'Actividades por usuario'},{id:'rptKardexCte',nombre:'Actividades por cliente'},{id:'rptKardexAct',nombre:'Resumen por Servicios'}];


        let modulos = [
                       {id:"inv", nombre:"Opciones", catalogos:catInv, movimientos:movInv, consultas:conInv, reportes:repInv}
                    ];
                    
    
        return (
            <ul className="nav">
                {modulos.map((e,i) =>{
                    return(
                        <li className="nav-item">
                            <Modulo modulo={e} opcionSeleccionada={this.props.opcionSeleccionada}/>
                        </li>    
                    )
                })}
            </ul>)
    }
}

class Modulo extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  onClick = (e) =>{
      this.toggle();
      //alert(e.target.dataset.opc);
      this.props.opcionSeleccionada(e.target.dataset.opc,e.target.innerText);
  }
  render() {
    const {modulo} = this.props;
    const titulo = modulo.nombre;
    const idMod = modulo.id;
    const catalogos = modulo.catalogos;
    const movimientos = modulo.movimientos;
    const consultas = modulo.consultas;
    const reportes = modulo.reportes;
    return (
      <div>
          <a  id={idMod} className="nav-link opcion" href="#">
          {titulo}
        </a>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={idMod} toggle={this.toggle} trigger="legacy" style={{width:400}}>
          <PopoverBody className="body" style={{padding:1}}>
              <div className="container" style={{padding:1}}>
                <div className="body" style={{padding:5}}>
                  <div className="row">
                      <div className="col">
                        <span><b>catalogos</b></span>
                        <hr className="lineaOut" />
                        <ul>
                            {catalogos.map((e,i) =>{ 
                              if(e.nombre == '-')
                              return <hr className="lineaIn" />
                            return <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>
                            })}
                        </ul>
                        <br/>
                        <span><b>Movimientos</b></span>
                        <hr className="lineaOut" />
                        <ul>
                            {movimientos.map((e,i) => <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                        <br/>
                        <hr className="lineaOut" />
                        <span><b>Consultas</b></span>
                        <hr className="lineaOut" />
                        <ul>
                            {consultas.map((e,i) => <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                       </div>
                       <br/>
                      <div className="col">
                        <span><b>Informes</b></span>
                        <hr className="lineaOut" />
                        <ul>
                            {reportes.map((e,i) => <li className="opcionMenu" data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                        <br/>
                      </div>
                  </div>
                  </div>
              </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

class ModuloSimple extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  onClick = (e) =>{
      this.toggle();
      //alert(e.target.dataset.opc);
      this.props.opcionSeleccionada(e.target.dataset.opc,e.target.innerText);
  }
  render() {
    const {modulo} = this.props;
    const titulo = modulo.nombre;
    const idMod = modulo.id;
    const opciones = modulo.opciones;
    return (
      <div>
          <a  id={idMod} className="nav-link opcion" href="#">
          {titulo}
        </a>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={idMod} toggle={this.toggle} trigger="legacy" style={{width:400}}>
          <PopoverBody className="body" style={{padding:1}}>
              <div className="container" style={{padding:1}}>
                <div className="body" style={{padding:5}}>
                  <div className="row">
                      <div className="col">
                        <ul>
                            {opciones.map((e,i) => <li data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                      </div>
                  </div>
                  </div>
              </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}