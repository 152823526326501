import React from 'react';
import { Nav, Navbar, NavItem, NavLink, NavbarBrand, Button } from 'reactstrap';
import SideBarMenu from './SideBarMenu';
import Login from './Login';
import Menu from './Menu';
import classnames from 'classnames';
import Usuarios from './cat/Usuarios';
import Pacientes from './cat/Pacientes';
import Especialidades from './cat/Especialidades';
import Citas from './mov/Citas';

class App extends React.Component {
  constructor(props) {
    super(props);
    let urlws ='';
    //urlws = 'http://localhost:10457';
    this.state = {
      u: { id: 0, nombre: '', admin: false, clinica:0 },
      lg: false,
      opc: '?',
      txt:'',
      temp:'2021',
      nombreEmpresa:'Empresa DEMO',
      urlws : urlws,
      activeTab:0,
      tabs:['Inicio'],
      componenteActivo:0,
      componentes: [
          <div><h1>Control de Clinicas</h1></div>,
      ]
    };
  }
  opcionSeleccionada = (opc, txt) => {
    const rpt = opc;
    let urlws = this.state.urlws;
    if(opc === "")
       return;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if(this.state.tabs[i] === txt ){
        this.setState({activeTab:i});
        return;
      }
    }
    if (opc === "especialidades")
      this.agregar(<Especialidades urlws={urlws} usuario={this.state.u} />);
    if (opc === "pacientes")
      this.agregar(<Pacientes urlws={urlws} usuario={this.state.u} />);
    if (opc === "usuarios")
      this.agregar(<Usuarios urlws={urlws} usuario={this.state.u} />);
    if (opc === "citas")
      this.agregar(<Citas urlws={urlws} usuario={this.state.u} />);
    /*
    if(opc === "rptBalanza" || opc === "rptBalance" || opc === "rptAnalitica" || opc === "rptAuxiliar" || opc === "rptResultados" || opc === "rptVerificador"  || opc === "rptPolizas")
      opc = "rptConta"
    if(opc === "rptLstEnt" || opc === "rptLstSal" || opc === "rptSdosAlm" || opc === "rptExisAct" || opc === "rptKardex"  || opc === "rptExis")
      opc = "rptInv"
    if(opc === "rptPapaProd" || opc === "rptCosechaGranos" || opc === "rptRemGranos" || opc === "rptRemGranosLiq" || opc == "rptCostoLoteCult" || opc == "rptAgriActLote" || opc == "rptAgriAct")
      opc = "rptAgri"
    if(opc === "rptNomActFecCon" || opc === "rptNomActFecDet" )
      opc = "rptNom"
    if (opc === "facturas")
      this.agregar(<Facturas urlws={urlws} usuario={this.state.u} />);
      */
    
    this.setState({
      tabs: this.state.tabs.concat(txt)
    });

    opc = "";
    this.setState({ opc, txt });
  }
  iniciar = (u) => {
    console.log(u);
    const usu = { id : u.Id, nombre : u.Nombre, tipo : u.Tipo, clinica : u.Clinica };
    this.setState({ lg: true, u: usu }, () => this.opcionSeleccionada("citas","Citas"));
  }
  agregar = (componente) => {
    this.setState({
      activeTab:this.state.tabs.length,
      componentes: this.state.componentes.concat(componente)
    });
  };
  toggle = (i) =>{
    const txt = this.state.tabs[i];
    this.setState({activeTab:i, componenteActivo:i, txt});
  }
  cerrar = (i) =>{
    if(i == 0)
       return;
    //this.state.componentes.splice(i,1);
    //this.state.tabs.splice(i,1);
    this.state.componentes[i] = null;
    this.state.tabs[i] = null;
    this.setState({activeTab:this.state.tabs.length-1});
  }
  render() {
    let urlws = this.state.urlws;
    let {opc} = this.state;
    if(opc === "rptBalanza" || opc === "rptBalance" || opc === "rptAnalitica" || opc === "rptAuxiliar" || opc === "rptResultados" || opc === "rptVerificador"  || opc === "rptPolizas")
      opc = "rptConta"
    if(opc === "rptLstEnt" || opc === "rptLstSal" || opc === "rptSdosAlm" || opc === "rptExisAct" || opc === "rptKardex"  || opc === "rptExis")
      opc = "rptInv"
    if(opc === "rptCosechaGranos" || opc === "rptRemGranos" || opc === "rptRemGranosLiq" || opc === "rptCostoLoteCult")
      opc = "rptAgri"
    const estilo = { visibility: this.state.lg ? 'visible' : 'hidden' };
    if(this.state.lg)
      document.body.classList.add('body');
    return (
      <div>
        {this.state.lg ? null : <Login iniciar={this.iniciar} nombre={this.state.nombreEmpresa} urlws={urlws} />}
        <div style={estilo}>
          <div className="row">
            <div className="col-1">
            </div>
            <div className="col">
              <div className="row">
                <div className="col-10">
                  <Menu opcionSeleccionada={this.opcionSeleccionada} usuario={this.state.u} />
                </div>
                <div className="col">
                  <span style={{ textAlign:'left', color: '#fff', fontWeight:'bolder' }}>usuario:{this.state.u.nombre}</span>
                </div>
              </div>
                <div className="row">
                  <div className="col-7">
                    <span className="opcionActual">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.txt}</span>
                  </div>
                </div>
            </div>
          </div>
          {this.state.lg ?
          <div id="content" style={{ width: '95%' }}>
              <div>
              <Nav tabs>
                {this.state.tabs.map((o,i)=>
                  o == null ? null :
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === i })}
                      onClick={() => { this.toggle(i); }}>
                        <span>{o}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        { i > 0 &&
                        <a href="#"><b><span onClick={()=>{this.cerrar(i)}}><img src="/imagenes/circle-x.png" alt="cerrar"/></span></b></a>
                        }
                        </NavLink>
                  </NavItem>
                )}
                </Nav>
              </div>    
            {
              this.state.componentes.map((o,i) => {
                const k = "k" + i;
                const visible = i == this.state.activeTab ?'block':'none';
                return <div key={k} style={{display:visible}}>{o}</div>
            })
            }
          </div>
          :null
          }
        </div>
      </div>
    )
  }
}
export default App;