import React from 'react';
import { ModalBody,InputGroup, InputGroupAddon, Modal, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Pacientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clinica:props.usuario.clinica,
            clave: '',
            nombre:'',
            apePat:'',
            apeMat:'',
            objeto:{},
            objetos: []
        };
        this.cargarObjetos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarObjetos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/paciente/lista?apePat=${this.state.apePat}&apeMat=${this.state.apeMat}&nombre=${this.state.nombre}`;
        const resp = await fetch(url);
        const objetos = await resp.json();
        this.setState({cargando:false, objetos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarObjetos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarObjetos();
    }    
    cargar = async (id) => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/paciente/cargar/${id}`;
        const resp = await fetch(url);
        const objeto = await resp.json();
        objeto.Usuario = this.props.usuario.id;
         this.setState({
            cargando:false,
            formVisible: true,
            clave: id,
            objeto
        });
    }
    guardar = () =>{
        this.onFormClose();
        this.cargarObjetos();
    }
    nuevo = () => {
        this.cargar(-1);
    }
    imprimir = () =>{
        const url = `${this.props.urlws}/usuario/listaPdf`;
        window.open(url, '', '');
    }
    excel = () =>{
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container style={{ padding: 10 }} >
                {this.state.cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="primary" />
                    </div>
                    :
                    null
                }
                <div style={estilo}>
                    <div className="row">
                        <div className="col">
                            <Form inline onSubmit={this.onSubmit} >
                            <Input size="sm" type="text" placeholder="Ap.Paterno" name="apePat" onChange={this.onChange} style={{ width: 130 }} />
                            <span>&nbsp;</span>
                            <Input size="sm" type="text" placeholder="Ap.Materno" name="apeMat" onChange={this.onChange} style={{ width: 130 }} />
                            <span>&nbsp;</span>
                                <InputGroup>
                                    <Input size="sm" type="text" placeholder="Nombre" name="nombre" onChange={this.onChange} style={{ width: 130 }} />
                                    <InputGroupAddon addonType="append">
                                        <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                    </InputGroupAddon>
                                    &nbsp;
                                    &nbsp;
                                    <Button size="sm" color="success" outline onClick={this.nuevo}><img src="/imagenes/add.png" /></Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button size="sm" color="success" outline onClick={this.imprimir}><img src="/imagenes/printer.png" /></Button>
                                    &nbsp;
                                    <Button size="sm" color="success" outline onClick={this.excel}><img src="/imagenes/excel.png" /></Button>
                                </InputGroup>
                            </Form>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <ObjetoTable objetos={this.state.objetos} height={500} width={990} onRowClick={this.cargar} />
                </div>
                <ObjetoForm urlws={this.props.urlws} visible={this.state.formVisible} objeto={this.state.objeto} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardar} />
            </Container>
        );
    }
}

class ObjetoTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { objetos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50}}>Id</th>
                        <th>Nombre</th>
                        <th style={{width:80}}>FechaNac</th>
                        <th style={{width:30}}></th>
                    </tr>
                </thead>
                <tbody>
                    {objetos.map((o, i) => (
                        <ObjetoRow key={i} objeto={o} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ObjetoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.objeto.I);
    }
    render(){
        let {objeto} = this.props;
        return (
        <tr>
            <td>{objeto.I}</td>
            <td>{objeto.N}</td>
            <td>{objeto.F}</td>
            <td>
                <img src="/imagenes/edit.png" onClick={this.onClick} alt="editar" title="editar"/>
            </td>
        </tr>
        );
    }
}

class ObjetoForm extends React.Component {
    constructor(props){
        super(props);
        let obj = props.objeto;
        this.state = {
            sexos:[{I:'M',N:'MUJER'},{I:'H',N:'HOMBRE'}],
            objeto:obj,
            cargando:false
        };
    }
    componentWillReceiveProps(newProps){
        let obj = newProps.objeto;
        this.setState({
            objeto:obj,
            cargando:false
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const obj = {
            ...this.state.objeto,
            [campo] : e.target.value
        };
        this.setState({objeto:obj});
    }
    onChecked = (e) => {
        const campo = e.target.name;
        const obj = {
            ...this.state.objeto,
            [campo] : e.target.checked
        };
        this.setState({objeto:obj});
    }
    guardar = () =>{
        if(this.state.objeto.Nombre.length < 3){
            Swal.fire('Atencion','Especifique el nombre','warning');
            return;
        }
        if(this.state.objeto.ApePat.length < 3){
            Swal.fire('Atencion','Especifique el apellido paterno','warning');
            return;
        }
        if(this.state.objeto.Sexo === "?"){
            Swal.fire('Atencion','Especifique el sexo','warning');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB(this.state.objeto);
            }
          })        
    }
    guardarB = async(dato) =>{
        const url = this.props.urlws +"/paciente/guardar";
        this.setState({cargando:true});
        if(dato.Codigo === 0)
           dato.Codigo = '';
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substring(0,3) !== "Err"){
            Swal.fire('Atencion','Datos guardados','success');
            this.props.guardar();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.objeto === undefined)
            return null;
        if(Object.keys(this.props.objeto).length === 0)
            return null;
        let id = this.state.objeto.Id;
        if(id == 0)
           id = "NUEVO";
        return (
            <Container style={estilo}>
                {this.state.cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="primary" />
                    </div>
                    :
                    null
                }
                <Form>
                    <div className="row">
                        <div className="col-1">
                            <FormGroup >
                                <span>Id</span>
                                <Input size="sm" type="text" name="Codigo" value={id} />
                            </FormGroup>
                        </div>
                        {' '}
                        <div className="col-3">
                            <FormGroup>
                                <span>Ap.Paterno</span>
                                <Input size="sm" type="text" name="ApePat" value={this.state.objeto.ApePat} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup>
                                <span>Ap.Materno</span>
                                <Input size="sm" type="text" name="ApeMat" value={this.state.objeto.ApeMat} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <FormGroup>
                                <span>Nombre</span>
                                <Input size="sm" type="text" name="Nombre" value={this.state.objeto.Nombre} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Fec.Nacimiento</span>
                                <Input size="sm" type="date" name="FechaNac" value={this.state.objeto.FechaNac} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <span>Sexo</span><br />
                            <Input size="sm" type="select" name="Sexo" value={this.state.objeto.Sexo} onChange={this.onChange}>
                                <option key={0} value='?'>Especifique</option>
                                {this.state.sexos.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </div>
                        <div className="col-5">
                            <FormGroup>
                                <span>Email</span>
                                <Input size="sm" type="text" name="Email" value={this.state.objeto.Email} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <FormGroup>
                                <span>Direccion</span>
                                <Input size="sm" type="text" name="Direccion" value={this.state.objeto.Direccion} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Telefono</span>
                                <Input size="sm" type="text" name="Telefono" value={this.state.objeto.Telefono} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <div className="row">
                    <div className="col">
                        <Button size="sm" color="success" onClick={this.guardar}>Aceptar</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button size="sm" color="danger" onClick={this.props.onClose}>Cancelar</Button>
                    </div>
                </div>
                <br/>
            </Container>
        );
    }
}