import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';

export default class Citas extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date().yyyymmdd('-');
        this.state = {
            clinica:props.usuario.clinica,
            mostrarDialogo:false,
            fecha:fec,
            medico:0,
            cargando:false,
            mostrarDialogo:false,
            editandoCita:false,
            id:0,
            obs:'',
            hora:'',
            apePat:'',
            apeMat:'',
            nombre:'',
            paciente:{},
            pacientes:[],
            horas:[],
            medicos:[],
            citas:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/usuario/medicos/${this.state.clinica}`;
        let resp = await fetch(url);
        const medicos = await resp.json();
        url = `${this.props.urlws}/cita/horas/${this.state.clinica}?fecha=${this.state.fecha}&medico=${this.state.medico}`;
        resp = await fetch(url);
        const horas = await resp.json();
        this.setState({cargando:false, medicos, horas });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarObjetos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    cargarObjetos = async (id) => {
        this.setState({cargando:true,editandoCita:false});
        let url = `${this.props.urlws}/cita/horas/${this.state.clinica}?fecha=${this.state.fecha}&medico=${this.state.medico}`;
        let resp = await fetch(url);
        const horas = await resp.json();
         this.setState({cargando:false,horas});
    }
    cargarPacientes = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/paciente/lista?apePat=${this.state.apePat}&apeMat=${this.state.apeMat}&nombre=${this.state.nombre}`;
        const resp = await fetch(url);
        const pacientes = await resp.json();
        this.setState({cargando:false, pacientes });
    }
    cargarPaciente = async (id) => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/paciente/cargar/${id}`;
        const resp = await fetch(url);
        const paciente = await resp.json();
        this.setState({cargando:false, paciente, mostrarDialogo:false, editandoCita:true });
    }
    cargarCita = async (id) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/cita/cargar/${id}`;
        let resp = await fetch(url);
        const cita = await resp.json();
        url = `${this.props.urlws}/paciente/cargar/${cita.Paciente}`;
        resp = await fetch(url);
        const paciente = await resp.json();
        this.setState({cargando:false, obs:cita.Texto,cita, paciente, mostrarDialogo:false, editandoCita:true });
    }
    onRowClick = (indice,hora) =>{
        let h = this.state.horas[indice].H1;
        let id = this.state.horas[indice].I1;
        if(hora == 2){
            h = this.state.horas[indice].H2;
            id = this.state.horas[indice].I2;
        }
        if(this.state.medico == 0){
            Swal.fire('Atencion','Especifique el medico','warning');
            return;
        }
        if(id == 0) // nueva cita
           this.setState({mostrarDialogo:true, hora:h, id});
        else{
            this.setState({hora:h},()=> { this.cargarCita(id)});
        }
    }
    cancelarCita = () =>{
        this.setState({editandoCita:false});
    }
    handleClose = () =>{
        this.setState({mostrarDialogo:false});
    }
    onBntBuscarClick = () =>{
        this.cargarObjetos();
    }  
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB();
            }
          })        
    }
    guardarB = async() =>{
        const dato = {
            Id : this.state.id,
            Clinica : this.state.clinica,
            Medico : this.state.medico,
            Paciente : this.state.paciente.Id,
            Fecha:this.state.fecha,
            Hora:this.state.hora,
            Usuario : this.props.usuario.id,
            Texto : this.state.obs
        }
        const url = this.props.urlws +"/cita/guardar";
        this.setState({cargando:true});
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substring(0,3) !== "Err"){
            Swal.fire('Atencion','Datos guardados','success');
            this.cargarObjetos();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
  
    render() {
        const estilo1 = { display: this.state.editandoCita ? 'none' : 'inline' };
        const estilo2 = { display: this.state.editandoCita ? 'inline' : 'none' };
        return(
            <Container style={{ padding: 10 }} >
                <div>
                    <table>
                        <tr>
                            <td>Medico</td>
                            <td><Input size="sm" type="select" name="medico" value={this.state.medico} onChange={this.onChange} style={{ width: '500px' }}>
                                <option key={0} value={0}>Especifique</option>
                                {this.state.medicos.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                            </td>
                            <td>Fecha</td>
                            <td><Input size="sm" type="date" value={this.state.fecha} name="fecha" onChange={this.onChange} style={{ width: '150px' }} />
                            </td>
                            <td>
                                <Button size="sm" color="success" onClick={this.onBntBuscarClick}>  <img src="/imagenes/ok.png" /> </Button>
                            </td>
                        </tr>
                    </table>
                    <div style={estilo1}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 50 }}>Hora</th>
                                <th style={{ width: 250 }}>Paciente</th>
                                <th style={{ width: 30 }}></th>
                                <th style={{ width: 50 }}>Hora</th>
                                <th style={{ width: 250 }}>Paciente</th>
                                <th style={{ width: 30 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.horas.map((o, i) => (
                                <ObjetoRow key={i} objeto={o} indice={i} onRowClick={this.onRowClick} />
                            ))}
                        </tbody>
                    </Table>
                    </div>
                </div>
                <div style={estilo2}>
                    <h5>Cita</h5>
                    <table>
                        <tr>
                            <td style={{width:'100px'}}>Hora</td>
                            <td><b>{this.state.hora}</b></td>
                        </tr>
                        <tr>
                            <td>Paciente</td>
                            <td>
                                <b>{this.state.paciente.ApePat + ' ' + this.state.paciente.ApeMat + ' ' + this.state.paciente.Nombre}</b>
                                <span>&nbsp;&nbsp;</span>
                                <Button size="sm" color="success" onClick={()=>this.setState({mostrarDialogo:true})}>  <img src="/imagenes/zoom.png" /> </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Observaciones</td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <textarea class="form-control" cols="100" rows="5" name="obs" value={this.state.obs} onChange={this.onChange}></textarea>
                            </td>
                        </tr>
                    </table>

                    <div className="row">
                        <div className="col">
                            <br />
                            <Button size="sm" color="success" onClick={this.guardar}>Aceptar</Button>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <Button size="sm" color="danger" onClick={this.cancelarCita}>Cancelar</Button>
                        </div>
                    </div>
                </div>
                <Modal size="lg" id="dlgPac" name="dlgPac" isOpen={this.state.mostrarDialogo} toggle={this.handleClose}>
                    <ModalBody>
                        <h5>Paciente</h5>
                        <div className="row">
                            <div className="col">
                                <Form inline onSubmit={this.onSubmit} >
                                    <Input size="sm" type="text" placeholder="Ap.Paterno" name="apePat" onChange={this.onChange} style={{ width: 130 }} />
                                    <span>&nbsp;</span>
                                    <Input size="sm" type="text" placeholder="Ap.Materno" name="apeMat" onChange={this.onChange} style={{ width: 130 }} />
                                    <span>&nbsp;</span>
                                    <InputGroup>
                                        <Input size="sm" type="text" placeholder="Nombre" name="nombre" onChange={this.onChange} style={{ width: 130 }} />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" onClick={this.cargarPacientes}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Form>
                            </div>
                        </div>
                        <div style={{ height: '400px', overflow: 'auto' }}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ width: 30 }}></th>
                                        <th style={{ width: 50 }}>Id</th>
                                        <th>Nombre</th>
                                        <th style={{ width: 80 }}>FechaNac</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.pacientes.map((o, i) => (
                                        <tr>
                                            <td>
                                                <img src="/imagenes/ok.png" onClick={() => this.cargarPaciente(o.I)} alt="seleccionar" title="selecionar" />
                                            </td>
                                            <td>{o.I}</td>
                                            <td>{o.N}</td>
                                            <td>{o.F}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div className="row">
                            <div className="col">
                                <br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button size="sm" color="danger" onClick={this.handleClose}>Cancelar</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}
class ObjetoRow extends React.Component{
    onClick1 = (e) =>{
        this.props.onRowClick(this.props.indice,1);
    }
    onClick2 = (e) =>{
        this.props.onRowClick(this.props.indice,2);
    }
    render(){
        let {objeto} = this.props;
        return (
        <tr>
            <td>{objeto.H1}</td>
            <td>{objeto.N1}</td>
            <td>
                <img src="/imagenes/edit.png" onClick={this.onClick1} alt="editar" title="editar"/>
            </td>
            <td>{objeto.H2}</td>
            <td>{objeto.N2}</td>
            <td>
                <img src="/imagenes/edit.png" onClick={this.onClick2} alt="editar" title="editar"/>
            </td>
        </tr>
        );
    }
}
